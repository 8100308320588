<template>
    <div class="wrapper padding-lr-sm">
        <div class="flex align-center justify-between margin-bottom-sm">
            <div class="flex align-center ">
                <el-button size="small" type="primary" round @click="toAddStaff(1)">新增全职员工</el-button>
                <el-button size="small" type="primary" round @click="toAddStaff(2)">新增实习生</el-button>
                <el-button size="small" type="primary" round @click="toAddStaff(3)">新增兼职</el-button>
            </div>
            <div class="flex align-center justify-end flex-sub">

                <el-radio-group v-model="type" class="margin-right-sm" size="small" @change="hanldSearch">
                    <el-radio-button :label="0">全部员工</el-radio-button>
                    <el-radio-button :label="1">全职员工</el-radio-button>
                    <el-radio-button :label="2">实习生</el-radio-button>
                    <el-radio-button :label="3">兼职</el-radio-button>
                </el-radio-group>

                <el-radio-group v-model="ht_type" class="margin-right-sm" size="small" @change="hanldSearch">
                    <el-radio-button :label="1">入职中</el-radio-button>
                    <el-radio-button :label="2">已入职</el-radio-button>
                </el-radio-group>


                <el-input v-model="keyword" placeholder="请输入姓名" class=" margin-right-sm top-search-input"
                          size="small"></el-input>
                <el-button size="small" type="primary" @click="hanldSearch">搜索</el-button>
            </div>

        </div>

        <el-table
                :data="entryList"
                header-row-class-name="table-header"
                row-class-name="table-row"
                :cell-style="{'text-align':'center'}"
                alig="center"
                border
                style="width: 100%"
        >
            <el-table-column width="70" type="index" align="center" label="序号"></el-table-column>
            <el-table-column label="员工类型" prop="staff_type" width="100">
                <template slot-scope="scope">
                    {{ scope.row.staff_type === 1 ? '全职' : scope.row.staff_type === 2 ? '实习生' : '兼职' }}
                </template>
            </el-table-column>
            <el-table-column label="姓名" prop="xingming" width="100"></el-table-column>
            <el-table-column label="Offer发送日期" prop="fasongriqi"></el-table-column>
            <el-table-column label="查看Offer" prop="phone">
                <template slot-scope="scope">
                    <el-button size="small" v-if="scope.row.offer_state === 1" type="primary"
                               @click="toOffer(scope.row.id,scope.row.staff_type)">查看Offer<br/>（待处理）
                    </el-button>
                    <el-button size="small" v-else-if="scope.row.offer_state === 2" type="success"
                               @click="toOffer(scope.row.id,scope.row.staff_type)">查看Offer<br/>（已接受）
                    </el-button>

                    <el-button size="small" v-else-if="scope.row.offer_state === 3" type="danger"
                               @click="toOffer(scope.row.id,scope.row.staff_type)">查看Offer<br/>（拒绝）
                    </el-button>
                    <el-button size="small" v-else-if="scope.row.offer_state === 4" type="success"
                               @click="toOffer(scope.row.id,scope.row.staff_type)">查看Offer<br/>（已反馈）
                    </el-button>
                </template>
            </el-table-column>
            <el-table-column label="填写信息" prop="tianxiexinxi_state">
                <template slot-scope="scope">
                    <div v-if="scope.row.offer_state === 2 || scope.row.offer_state === 4">
                        <el-button size="small" v-if="scope.row.tianxiexinxi_state === 2" type="success"
                                   @click="toInfo(scope.row.id,scope.row.staff_type)">查看信息<br/>（已填写）
                        </el-button>
                        <el-button size="small" v-else-if="scope.row.tianxiexinxi_state ===1" type="warning"
                                   @click="toInfo(scope.row.id,scope.row.staff_type)">查看信息<br/>（待填写）
                        </el-button>
                    </div>
                    <div v-else>
                        <el-button size="small" type="info">查看信息</el-button>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="入职通知" prop="ruzhitongzhi_state">
                <template slot-scope="scope">
                    <div v-if="scope.row.tianxiexinxi_state === 2">
                        <el-button size="small" v-if="scope.row.ruzhitongzhi_state === 0" type="primary"
                                   @click="toEntry(scope.row.id,scope.row.staff_type)">生成入职通知
                        </el-button>
                        <el-button size="small" v-else-if="scope.row.ruzhitongzhi_state === 2" type="warning"
                                   @click="toEntry(scope.row.id,scope.row.staff_type)">查看通知<br/>（待反馈）
                        </el-button>
                        <el-button size="small" v-else-if="scope.row.ruzhitongzhi_state === 3" type="success"
                                   @click="toEntry(scope.row.id,scope.row.staff_type)">查看通知<br/>（已反馈）
                        </el-button>
                    </div>
                    <div v-else>
                        <el-button size="small" type="info">生成入职通知</el-button>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="入职日期" prop="ruzhiriqi"></el-table-column>
            <el-table-column label="合同" prop="hetong_state">
                <template slot-scope="scope">
                    <div v-if="scope.row.ruzhitongzhi_state === 3">
                        <el-button size="small" v-if="scope.row.hetong_state === 0" type="primary"
                                   @click="toContract(scope.row.id,scope.row.staff_type)">生成合同
                        </el-button>
                        <el-button size="small" v-else-if="scope.row.hetong_state === 1" type="warning"
                                   @click="toContract(scope.row.id,scope.row.staff_type)">查看合同 <br/>（待反馈）
                        </el-button>
                        <el-button size="small" v-else-if="scope.row.hetong_state === 2" type="success"
                                   @click="toContract(scope.row.id,scope.row.staff_type)">查看合同 <br/>（已反馈）
                        </el-button>
                    </div>
                    <div v-else>
                        <el-button size="small" type="info">生成合同</el-button>
                    </div>
                </template>


            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-popconfirm
                            title="您确定要删除吗？"
                            @confirm="delItem(scope.row.id)"
                    >
                        <el-button  size="small" type="danger" slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
                v-if="entryList.length > 0"
                class="page-all"
                background
                @current-change="getList"
                :current-page.sync="page"
                :page-size="limit"
                layout="prev, pager, next, jumper"
                :total="total"
        >
        </el-pagination>

    </div>
</template>

<script>
import {delOffer, getAdminOfferList} from "@/api/entry";

export default {
    name: "entryList",
    data() {
        return {
            keyword: '',
            type: 0,
            ht_type: 1,
            page: 1,
            limit: 10,
            total: 0,
            entryList: [],
            entryDialog: false,
            setId: 0,
            setItem: {},
        }
    },
    created() {
        this.$emit("setIndex", this.$route.path, "员工入职", false);
        this.getList()
    },
    components: {},
    methods: {

        //  新增员工
        toAddStaff(type) {
            if (type === 1) this.$router.push('/admin/fullTime/offerInfo/0')
            if (type === 2) this.$router.push('/admin/practiceTime/offerInfo/0')
            if (type === 3) this.$router.push('/admin/partTime/offerInfo/0')
        },
        //  去toOffer
        toOffer(id, type) {
            if (type === 1) this.$router.push(`/admin/fullTime/offerInfo/${id}`)
            if (type === 2) this.$router.push(`/admin/practiceTime/offerInfo/${id}`)
            if (type === 3) this.$router.push(`/admin/partTime/offerInfo/${id}`)
        },
        //  去员工信息
        toInfo(id, type) {
            if (type === 1) this.$router.push(`/admin/fullTime/staffInfo/${id}`)
            if (type === 2) this.$router.push(`/admin/practiceTime/staffInfo/${id}`)
            if (type === 3) this.$router.push(`/admin/partTime/staffInfo/${id}`)
        },
        //  去入职通知
        toEntry(id, type) {
            if (type === 1) this.$router.push(`/admin/fullTime/entryInfo/${id}`)
            if (type === 2) this.$router.push(`/admin/practiceTime/entryInfo/${id}`)
            if (type === 3) this.$router.push(`/admin/partTime/entryInfo/${id}`)
        },
        //  去合同
        toContract(id, type) {
            console.log(123)
            if (type === 1) this.$router.push(`/admin/fullTime/contractInfo/${id}`)
            if (type === 2) this.$router.push(`/admin/practiceTime/contractInfo/${id}`)
            if (type === 3) this.$router.push(`/admin/partTime/contractInfo/${id}`)
        },
        //  搜索
        hanldSearch() {
            this.page = 1
            this.getList()
        },
        //  获取专家表列表
        async getList() {
            const res = await getAdminOfferList(this.page, this.limit, this.keyword, this.type,this.ht_type)
            this.entryList = res.data
            this.total = res.total_number
        },
        async delItem(id) {
            await delOffer(id)
            this.$message.success('删除成功')
            if (this.page !== 1 && this.entryList.length === 1) this.page--
            await this.getList()
        }
    },
    filters: {},
    watch: {},
}
</script>

<style scoped lang="scss">

</style>
